<template>
  <div>
    <section class="white">
      <v-container>
        <v-row class="pb-5 pt-5">
          <v-col
            cols="10"
            offset="1"
            style="font-family:'Kanit'!important"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <h2
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
                class="mb-2"
                style="font-family:'Kanit'!important"
              >
                PONAD 100 LAT HISTORII
              </h2>
              <p
                class="subtitle-1 text-justify mb-1"
                style="font-family:'Kanit'!important"
              >
                DOŚWIADCZENIE
              </p>
            </v-col>
            <v-container class="d-inline-flex">
              <v-col cols="12">
                <v-card
                  outlined
                  tile
                  class="mb-5 centered text-justify"
                >
                  <!-- <v-card-title>
                    SAFE INVESTMENTS FOR THE TIMES
                  </v-card-title> -->
                  <v-card-text>
                    Korzenie naszej spółki sięgają roku 1920 kiedy to powstała Spółka Akcyjna „Wagon”. Pierwsze wagony opuściły halę produkcyjną kilkanaście miesięcy później, a po niespełna dwóch latach budowy, w 1922 roku, fabryka produkowała wagony pełną mocą. „Energetyka” w tej firmie ma początek na generatorze około 220 kW napędzanym silnikiem Diesla o mocy 300 KM. Dziś produkcja wagonów w Europejskim Konsorcjum Kolejowym WAGON Sp. z o.o. pochłania 1500 kW energii w każdej godzinie a dla potrzeb dystrybucji i obrotu energią elektryczną, dla całego otoczenia gospodarczego, w grudniu 2006 roku powołana została nasza spółka Energetyka WAGON Sp. z o.o.
                  </v-card-text>
                </v-card>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
        <v-row class="pb-5 pt-5 background-light-grey">
          <v-col
            cols="10"
            offset="1"
            style="font-family:'Kanit'!important"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <p
                class="subtitle-1 text-justify mb-1"
                style="font-family:'Kanit'!important"
              >
                PIERWSZY GENERATOR I PIERWSZA ELEKTROWNIA
              </p>
            </v-col>
            <v-container class="d-md-inline-flex">
              <v-col
                md="5"
                offest-md="1"
                sm="12"
              >
                <v-card
                  outlined
                  theme--dark
                  class="mb-5 centered text-justify"
                >
                  <v-img src="/static/xstara1.jpg" />
                </v-card>
              </v-col>
              <v-spacer />
              <v-col
                md="5"
                offest-md="1"
                sm="12"
              >
                <v-card
                  outlined
                  theme--dark
                  class="mb-5 centered text-justify"
                >
                  <v-img src="/static/xstara3.jpg" />
                </v-card>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
        <v-row class="pb-5 pt-5">
          <v-col
            cols="10"
            offset="1"
            style="font-family:'Kanit'!important"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <p
                class="subtitle-1 text-justify mb-1"
                style="font-family:'Kanit'!important"
              >
                POTENCJAŁ TECHNICZNY ENERGETYKI WAGON
              </p>
            </v-col>
            <v-container class="d-inline-flex">
              <v-col cols="12">
                <v-card
                  outlined
                  tile
                  class="mb-5 centered text-justify"
                >
                  <v-card-text>
                    <p>
                      Nasz majątek to, między innymi :
                    </p>
                    <ul>
                      <li>Główna Podstacja Zasilająca (GPZ) – stanowiąca źródło zaopatrzenia w energię elektryczną dla kilkunastu podmiotów gospodarczych ulokowanych w naszym sąsiedztwie</li>
                      <li>Linie kablowe 15 kV o łącznej długości 4 790 mb</li>
                      <li>Linie kablowe 6 kV o łącznej długości 3 810 mb</li>
                      <li>Transformatory 15/6 kV, 15/3 kV, 15/0,4 kV i 6/0,4 kV – łącznie 14 sztuk</li>
                      <li>Rozdzielnie 15 kV, 6 kV i 0,4 kV – łącznie 21 sztuk</li>
                    </ul>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
        <v-row class="pb-5 pt-5">
          <v-col
            cols="10"
            offset="1"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <h2
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
                class="mb-2"
                style="font-family:'Kanit'!important"
              >
                ZARZĄD
              </h2>
            </v-col>
            <v-container
              class="d-md-inline-flex"
              style="font-family:'Kanit'!important"
            >
              <v-col
                md="4"
                sm="12"
                class="text-center"
              >
                <v-avatar
                  size="200"
                  class="mb-5"
                >
                  <img src="/static/baczkowskipawel.png">
                </v-avatar>
                <hr>
                <p class="mt-5">
                  Paweł Bączkowski
                </p>
                <p style="font-size:0.75rem">
                  PREZES ZARZĄDU
                </p>
              </v-col>
              <v-col
                md="4"
                sm="12"
                class="text-center"
              >
                <v-avatar
                  size="200"
                  class="mb-5"
                >
                  <img src="">
                </v-avatar>
                <hr>
                <p class="mt-5">
                  Sławomir Górny
                </p>
                <p style="font-size:0.75rem">
                  DYREKTOR DS. HANDLOWYCH
                </p>
              </v-col>
              <v-col
                md="4"
                sm="12"
                class="text-center"
              >
                <v-avatar
                  size="200"
                  class="mb-5"
                >
                  <img src="">
                </v-avatar>
                <hr>
                <p class="mt-5">
                  Małgorzata Dolska
                </p>
                <p style="font-size:0.75rem">
                  KIEROWNIK BIURA
                </p>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
        <v-row
          class="pb-5 pt-5 background-light-grey"
        >
          <v-col
            cols="10"
            offset="1"
            style="font-family:'Kanit'!important"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <h2
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
                class="mb-2"
                style="font-family:'Kanit'!important"
              >
                MISJA
              </h2>
              <p
                class="subtitle-1 text-justify mb-1"
                style="font-family:'Kanit'!important"
              >
                ENERGETYKA WAGON SP. Z O.O.
              </p>
            </v-col>
            <v-container>
              <v-col cols="12">
                <v-card
                  outlined
                  tile
                  class="mb-5 centered text-justify"
                >
                  <v-card-text>
                    <h2>„DOBRA ROBOTA”</h2>
                    <p>
                      czyli spełnienie oczekiwań naszych klientów w zakresie dobrej jakości usług i odbiorców w zakresie dostaw energii elektrycznej. Truizm?! Niekoniecznie! Z wielu powodów; dziś coraz trudniej o zwyczajną, rzetelną, terminową pracę. Realizując misję, Zarząd Spółki Energetyka WAGON zobowiązuje się do ciągłego doskonalenia działalności, a w szczególności do:
                    </p>
                    <ul>
                      <li>doskonalenia usług w oparciu o aktualne i przyszłe potrzeby klientów</li>
                      <li>motywowania pracowników sprzyjającego ciągłej poprawie jakości pracy</li>
                      <li>kształcenie pracowników, podnoszenie ich świadomości, kwalifikacji i umiejętności</li>
                      <li>utrwalania w świadomości personelu przekonania, iż za jakość pracy oraz bezpieczeństwo człowieka i środowiska odpowiedzialni są wszyscy pracownicy Spółki</li>
                      <li>utrwalania świadomości, że zaufanie do firmy jest wynikiem rzetelności i odpowiedzialności wobec otoczenia zapobiegania wypadkom i chorobom zawodowym</li>
                      <li>dbałość o środowisko w trosce o zdrowie przyszłych pokoleń</li>
                    </ul>
                    <br>
                    <p>
                      Jesteśmy firmą, z którą warto współpracować i dla której warto pracować. Stawiamy na:
                    </p>
                    <ul>
                      <li>odpowiedzialność - każdy z nas wie, że wszystko od niego zależy</li>
                      <li>innowacyjność – zabieganie o nowe pomysły i ich realizacja</li>
                      <li>uczciwość - uczciwe zachowanie wobec klienta i względem siebie</li>
                      <li>szacunek - wykorzystanie pełnych możliwości zespołu poprzez wkład każdej osoby</li>
                    </ul>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<style scoped>
  .pure-border {
    border-left: 3px solid black;
  }
  .background-light-grey {
    background-color: #f1f1f1;
  }
</style>

<script>
  export default {
    data: () => ({
      highlights: [
        'Projekty i architektura',
        'Wykończenia wnętrz',
        'Inwestor zastępczy',
        'Wykonawstwo i nadzory',
        'Marketing',
        'Sprzedaż'
      ],
      experiences: [
        ['mdi-vuejs', '75', 'Vue'],
        ['mdi-vuetify', '100', 'Vuetify'],
        ['mdi-aws', '67', 'AWS'],
        ['mdi-server-security', '95', 'Security']
      ]
    })
  }
</script>
